

html{
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
}
body {
    background-color: #f0f2f5;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

}
#root{
    width: 100%;
    height: 100%;
}

.goButtonContainer{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 550px;
    height: 351px;
    margin: auto;
}
.goButton{
    background-color: #53d769 !important;
    color: white !important;
    font-size: 72px !important;
    width: 550px;
    height: 351px;
}
.goButtonActive{
    background-color: rgb(83, 180, 105)!important;
    font-size: 5em !important;
    font-weight: bolder;
    text-shadow: 0 1px 0 #ccc,
                 0 2px 0 #c9c9c9,
                 0 3px 0 #bbb,
                 0 4px 0 #b9b9b9,
                 0 5px 0 #aaa,
                 0 6px 1px rgba(0,0,0,.1),
                 0 0 5px rgba(0,0,0,.1),
                 0 1px 3px rgba(0,0,0,.3),
                 0 3px 5px rgba(0,0,0,.2),
                 0 5px 10px rgba(0,0,0,.25),
                 0 10px 10px rgba(0,0,0,.2),
                 0 20px 20px rgba(0,0,0,.15);
    text-decoration: underline !important;
}


@media only screen and (max-width: 768px) {
    .goButtonContainer{
        width:  100%;
        height: 100%;
    }
    .goButton{
        width:  100%;
        height: 100%;
    }
  }

.qrCodeButton{
    border: 1px solid #000;
    font-size: 12px;
    padding: 3px;
}
.controlRoomContainer{
    padding-top: 2%;
}

.headerGrid{
padding: 5px 0;
}

.headerGridItem{
    text-align: center;
}
.controlRoomText{
    font-weight: 600;
}
.contentGridItem{
    margin-top: 15px !important;
}

.contentGridItem .MuiPaper-outlined{

    padding: 15px 0;
}

.qrCodeImage{
    min-height: 400px;
}

.goGreen{
    background-color: rgba(0, 230, 64, 1) !important;
}

#ns{
    display: none;
}

.MuiTypography-body2{
    font-size: 1rem !important;
}

.MuiSvgIcon-root{
    font-size: 1.4rem !important;

}